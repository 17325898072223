import { rightIcon } from 'icons'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './404.css'
import StackTracey from 'stacktracey'
import { addDoc, collection } from 'firebase/firestore'
import { firestore } from 'services/firebase'

export default function ErrorPage ({ error }){
    const [errorCode, setErrorCode] = useState(null);
    useEffect(() => {
        const stack = new StackTracey(error);
        stack.withSourcesAsync().then((stack) => {
            const topItem = stack.items[0];
            addDoc(collection(firestore, 'errors'), {
                error: error.toString(),
                platform: 'webapp',
                timestamp: new Date(),
                stack: stack.items.map((item) => ({
                    callee: item.callee,
                    file: item.fileName,
                    line: item.line,
                    column: item.column,
                    source_line: item.sourceLine,
                })),
                file: topItem.fileName,
                line: topItem.line,
                column: topItem.column,
                source_line: topItem.sourceLine,
            }).then((docRef) => {
                setErrorCode(docRef.id);
            });
        });
    }, [error]);

    return (
        <div className='NotFoundBox'>
            <div className='errorBox'>
                <div className="errorText">Sorry, beim Anzeigen dieser Seite gab es einen Fehler </div>
                {errorCode && <>
                    <div className="errorTextSmall">Error-Code: {errorCode}</div>
                    <div className="errorTextSmall">Bitte teile uns diesen Errorcode mit, falls du dem Support (<a href="mailto:support@elgio.de">support@elgio.de</a>) diesen Fehler melden willst.</div>
                </>}
            </div>
            <Link to={"/"} className='HomeLink'>
                <div>Home</div>
                <div style={{fontSize: "var(--fontsize-large)"}}>{rightIcon}</div>
            </Link>
        </div>
    )
}