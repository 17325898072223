import { useEffect, useLayoutEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import ReactModal from "react-modal";
import { Link, useLocation } from "react-router-dom";

export default function ConsentBanner() {
    const location = useLocation();

    const [cookies, setCookie, removeCookie] = useCookies(['consent']);
    const [open, setOpen] = useState(!cookies.consent);
    const [closedOverride, setClosedOverride] = useState(false); //for legal pages
    const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

    useLayoutEffect(() => {
        setClosedOverride(
            location.pathname.includes('legal-notice')
            || location.pathname.includes('privacy-policy')
            || location.pathname.includes('terms-of-service')
        );
    }, [location.pathname]);

    //track page views
    useEffect(() => {
        if(!cookies.consent) return;
        window.fbq && window.fbq('track', 'PageView');
        window.ttq?.page();
    }, [cookies.consent, location]);

    const accept = () => {
        setOpen(false);
        setCookie('consent', true);
    };

    const decline = () => {
        setOpen(false);
        removeCookie('consent');
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <>
        {cookies.consent && <Helmet>
            <script>
                {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '870307518003685');
                    fbq('track', 'PageView');
                `}
            </script>
            <script>
                {`
                    //Part1
                    !function (w, d, t) {
                        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
                    
                    //Part 2
                        ttq.load('CL3ODE3C77U24LQ0E0I0');
                        ttq.page();
                    }(window, document, 'ttq');
                `}
            </script>
        </Helmet>}
        <ReactModal
            isOpen={open && !closedOverride}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    zIndex: 1000,
                },
                content: {
                    position: 'fixed',
                    top: isMobile ? '15%' : 'unset',
                    left: isMobile ? '50%' : 'auto',
                    right: isMobile ? "50%" : 'auto',
                    width: isMobile ? '350px' : '100vw',
                    height: isMobile ? '486px' : 'unset',
                    margin: isMobile ? '0 0 0 -175px' : '0',
                    backgroundColor: 'rgb(38, 38, 38)',
                    color: 'white',
                    border: 'none',
                    borderRadius: isMobile ? '5px' : '0px 0px 5px',
                    zIndex: 1001,
                    bottom: isMobile ? 'unset' : '0px',
                }
            }}
        >
            <div className="h-full flex flex-col gap-3">
                <div className="flex md:flex-row flex-col items-center gap-4">
                    <div>
                        <div className="font-bold">Hinweis</div>
                        <div className="small text-gray-400">
                            Mit deinem Einverständnis nutzt ELGIO Technologien laut DGSVO Art. 6 Abs. 1, Buchst. a&#41; zur Verarbeitung deiner IP Adresse und anderer Identifikatoren und teilt diese auch mit ihren Partnern (darunter auch in Drittländern), wie z.B. Veranstalter oder Marketingdienstleister, zur weiteren Verarbeitung für Marketing- und Statistikzwecke. Mehr zu der Verarbeitung und unseren Partnern findest du in unserer Datenschutzerklärung. Du kannst deine Einverständnis in der Fußzeile unter "Einverständnis zur Verarbeitung personenbezogener Daten" jederzeit zurückziehen oder neu erteilen.
                        </div>
                    </div>
                    <div className="flex md:flex-row flex-col md:w-fit w-full gap-3">
                        <button
                            onClick={accept}
                            className="b-white t-black md:w-fit w-full h-fit p-4 rounded-full font-semibold whitespace-nowrap text-center"
                            >
                            Alle Technologien erlauben
                        </button>
                        <button
                            onClick={decline}
                            className="b-lightgrey t-grey md:w-fit w-full h-fit p-4 rounded-full font-semibold whitespace-nowrap text-center"
                            >
                            Nur erforderliche erlauben
                        </button>
                    </div>
                </div>
                <div className="flex gap-2 text-xs md:justify-normal justify-center text-gray-400">
                    <Link to="/privacy-policy" className="hover:text-white">Datenschutz</Link>
                    <Link to="/legal-notice" className="hover:text-white">Impressum</Link>
                </div>
            </div>
        </ReactModal>
    </>;
}