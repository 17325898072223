import "./Footer.css";
import { Link } from "react-router-dom";
import { instagramIcon, spotifyIcon, twitterIcon } from "icons";
import { useCookies } from "react-cookie";


export default function Footer({ minimal }) {
    const [, , removeCookie] = useCookies(['consent']);
    const resetConsent = () => {
        removeCookie('consent');
        window.location.reload();
    };

    return <>
        <div className="footer">
            {!minimal && <>
                <div className="buttonBox">
                    {/* <a href="https://apps.apple.com/de/app/elgio/id1518327289">
                        <div className="getApp"> {iosIcon} App holen</div>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=de.elgio.lets_go_out&hl=de&gl=US">
                        <div className="getApp"> {androidIcon} App holen</div>
                    </a> */}
                </div>
                <div className="menuBox">
                    <div className="menu">
                        <div className="menuTitle">Info</div>
                        <div className="menuItemBox">
                            <div className="menuItem">
                                <Link to="/articles">Artikel</Link>
                            </div>
                        </div>
                    </div>
                    <div className="menu">
                        <div className="menuTitle">About</div>
                        <div className="menuItemBox">
                            <div className="menuItem">
                                <Link to="/about-us">Über uns</Link>
                            </div>
                            <div className="menuItem">
                                <Link to="/team">Team</Link>
                            </div>
                        </div>
                    </div>
                    <div className="menu">
                        <div className="menuTitle">Support</div>
                        <div className="menuItemBox">
                            <div className="menuItem">
                                <Link to="/faq">FAQ</Link>
                            </div>
                            <div className="menuItem">
                                <Link to="/contact">Kontakt</Link>
                            </div>
                            {/* <div className="menuItem">
                                <Link to="/press">Presse</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="socialMediaBox">
                    <a href="https://instagram.com/elgioapp" target="_blank" rel="noopener noreferrer">
                        <div className="socialMediaIcon">{instagramIcon}</div>
                    </a>
                    <a href="https://open.spotify.com/user/mffbq575bbx3acjd915t45vl1" target="_blank" rel="noopener noreferrer">
                        <div className="socialMediaIcon">{spotifyIcon}</div>
                    </a>
                    <a href="https://twitter.com/Elgioapp" target="_blank" rel="noopener noreferrer">
                        <div className="socialMediaIcon">{twitterIcon}</div>
                    </a>
                </div>
            </>}
            <div className="legalBox">
                <div className="tinyText">
                    <Link to="/privacy-policy">Datenschutzerkärung</Link>
                </div>
                <div className="tinyText">
                    <Link to="/legal-notice">Impressum</Link>
                </div>
                <div className="tinyText">
                    <button onClick={resetConsent}>Verarbeitung personenbezogener Daten</button>
                </div>
            </div>
            <div className="copyrightBox">
                {!minimal && <div className="logoName">ELGIO</div>}
                <div className="tinyText">2024 Copyright by Elgio UG (haftungsbeschränkt)</div>
            </div>
        </div>
    </>
}